import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  Card,
  Paper,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate, useParams } from "react-router-dom";
import { getregion } from "../../slices/setting";
import {
  createproduct,
  createspare,
  getaccessory,
  getproduct,
  getspare,
} from "../../slices/product";
// import './style.css';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Stepper, Step, StepLabel } from "@mui/material";
import Modal from "@mui/material/Modal";
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomTable2 from "../../components/table/Table2";
import { createproductstock } from "../../slices/inventory";
import { getwarrantybyid, getwarrantybyidoem, updatewarrantybyid } from "../../slices/warranty";
import { updatepurchasebyid } from "../../slices/orders";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const steps = [
  "Step 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
  "Step 6",
  "Step 7",
];

const Warrantydetail = ({ setLoader, show }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [file, setFile] = useState(null);

  const params = useParams();

  const [activeStep, setActiveStep] = useState(1);

  const [transadat, setTransadat] = useState({
    company:"",
    contact_number:"",
    transport:"",
    tracking_id: "",
    payment: "",
    date: "",
    file: file,
  });

  const [insdata, setInsdata] = useState({
    "inspection_report":"",
    "inspection_remarks":""
  });

  const [servicedata, setServicedata] = useState({
    "service": true,
    "service_on": "",
    "remarks":""
  });

  const [replacedata, setReplacedata] = useState({
    "service": true,
    "item": "",
    "product_no":"",
    "remarks":""
  });

  const dispatch = useDispatch();

  const { detail: purchaseDetail } =
    useSelector((state) => state.warranty) || [];

  console.log(purchaseDetail);

  useEffect(() => {
    setActiveStep(
      !purchaseDetail?.warranty_created
        ? 0
        : !purchaseDetail?.claim_verification
        ? 1
        : !purchaseDetail?.in_transit
        ? 2
        : !purchaseDetail?.inspection
        ? 3
        : !purchaseDetail?.under_service_replace
        ? 4
        : !purchaseDetail?.returning
        ? 5
        : !purchaseDetail?.out_for_delivery
        ? 6
        : 7
    );
  }, [purchaseDetail]);

  useEffect(() => {
    setLoader(true);
    dispatch(getwarrantybyidoem(params.warrantyId))
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    const formData = {claim_verification: true,
      claim_verification_date: `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`};
    const w_id = params.warrantyId
    setLoader(true)
    dispatch(updatewarrantybyid({w_id, formData}))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  const handleInspection = (e) => {
    e.preventDefault();
    const formData = {
      ...insdata,
      inspection: true,
      inspection_date: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
    };
    const w_id = params.warrantyId;
    setLoader(true);
    dispatch(updatewarrantybyid({ w_id, formData }))
      .then(() => {
        setLoader(false);
        handleClose();
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleService = (e) => {
    e.preventDefault();
    const formData = {
      under_service_replace_details: (purchaseDetail?.inspection_report === "Service" && servicedata.service) ? {...servicedata} : {...replacedata},
      new_item_no: (purchaseDetail?.inspection_report === "Service" && servicedata.service) ? purchaseDetail?.item_no : replacedata?.product_no,
      date_of_replace: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
      under_service_replace: true,
      under_service_replace_date: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
    };
    const w_id = params.warrantyId;
    setLoader(true);
    dispatch(updatewarrantybyid({ w_id, formData }))
      .then(() => {
        setLoader(false);
        handleClose();
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleReturn = (e) => {
    e.preventDefault();
    const formData = {
      return_transit_details: { ...transadat, file: file },
      returning: true,
      returning_date: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
    };
    const w_id = params.warrantyId;
    setLoader(true);
    dispatch(updatewarrantybyid({ w_id, formData }))
      .then(() => {
        setLoader(false);
        handleClose();
      })
      .catch(() => {
        setLoader(false);
      });
  };

  console.log(purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail.assign_products_list));

  const inveData = {
    data: purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail?.assign_products_list).map(obj =>{return {
      vehicle_id: obj.vehicle_id,
      variant_id: obj.variant_id,
      stock_id: obj.stock_id
    }})
  }

  const handleReceive = (e) => {
    e.preventDefault();
    const formData = {
      out_for_delivery: true,
      out_for_delivery_date: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
    };
    const p_id = params.purchaseID;
    setLoader(true);
    dispatch(updatepurchasebyid({ p_id, formData }))
      .then(() => {
        setLoader(false);
        dispatch(createproductstock(inveData))
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box>
        <Grid
          container
          spacing={3}
          sx={{ paddingBottom: 2, alignItems: "start" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>{params.warrantyId}</Typography>
          </Grid>
          <Grid item xs={12}>
          <Card sx={{p: 2}}>
          <Grid container spacing={2} sx={{alignItems:'center'}}>
          <Grid item xs={1.7}>
                <Typography variant="h6">Dealer ID</Typography>
                <Typography variant="p">{purchaseDetail?.dealer_id}</Typography>
            </Grid>
            <Grid item xs={1.7}>
                <Typography variant="h6">Dealer Name</Typography>
                <Typography variant="p">{purchaseDetail?.dealer_name}</Typography>
            </Grid>
            <Grid item xs={1.7}>
                <Typography variant="h6">Model</Typography>
                <Typography variant="p">{purchaseDetail?.select_vehicle}</Typography>
            </Grid>
            <Grid item xs={1.7}>
                <Typography variant="h6">Item</Typography>
                <Typography variant="p">{purchaseDetail?.item}</Typography>
            </Grid>
            <Grid item xs={1.7}>
                <Typography variant="h6">Item</Typography>
                <Typography variant="p">{purchaseDetail?.hsn_no}</Typography>
            </Grid>
            <Grid item xs={1.7}>
                <Typography variant="h6">Claim Date</Typography>
                <Typography variant="p">{(new Date(purchaseDetail?.created_at)).getDate()} / {(new Date(purchaseDetail?.created_at)).getMonth()+1} / {(new Date(purchaseDetail?.created_at)).getFullYear()}</Typography>
            </Grid>
            <Grid item xs={1.7}>
                <Typography variant="h6">Warranty Still</Typography>
                <Typography variant="p">{purchaseDetail?.still_warranty}</Typography>
            </Grid>
          </Grid>
          </Card>
          </Grid>
          <Grid item xs={12} sx={{mt: 2}}>
              <Grid container rowSpacing={2}>
                  <Grid item xs={4} sx={{border:'1px solid #bababa', p: 2}}>
                  <Typography variant="h5">Warranty Status</Typography>
                  <Divider sx={{my: 2}}/>
                  <Stepper
                    orientation="vertical"
                    activeStep={activeStep}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>
                          <Typography variant="body1">
                            {label === "Step 1" &&
                              (purchaseDetail?.warranty_created
                                ? "Created"
                                : "Not Created")}
                            {label === "Step 2" &&
                              (purchaseDetail?.claim_verification
                                ? "Verified"
                                : "Pending Verify")}
                            {label === "Step 3" &&
                              (purchaseDetail?.in_transit
                                ? "In Transit"
                                : "Waiting for In Transit")}
                            {label === "Step 4" &&
                              (purchaseDetail?.inspection
                                ? "Inspection Completed"
                                : "Waiting for inspection")}
                            {label === "Step 5" &&
                              (purchaseDetail?.under_service_replace
                                ? "Service/Replacement Completed"
                                : "Waiting for Under Service/Replacement")}
                            {label === "Step 6" &&
                              (purchaseDetail?.returning
                                ? "Returned"
                                : "Returning")}
                            {label === "Step 7" &&
                              (purchaseDetail?.out_for_delivery
                                ? "Received"
                                : "Out for delivery")}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            {label === "Step 1" &&
                              (purchaseDetail?.warranty_created
                                ? purchaseDetail?.warranty_created_date
                                : null)}
                            {label === "Step 2" &&
                              (purchaseDetail?.claim_verification
                                ? purchaseDetail?.claim_verification_date
                                : null)}
                            {label === "Step 3" &&
                              (purchaseDetail?.in_transit
                                ? purchaseDetail?.in_transit_date
                                : null)}
                            {label === "Step 4" &&
                              (purchaseDetail?.inspection
                                ? purchaseDetail?.inspection_date
                                : null)}
                            {label === "Step 5" &&
                              (purchaseDetail?.under_service_replace
                                ? purchaseDetail?.under_service_replace_date
                                : null)}
                            {label === "Step 6" &&
                              (purchaseDetail?.returning
                                ? purchaseDetail?.returning_date
                                : null)}
                            {label === "Step 7" &&
                              (purchaseDetail?.out_for_delivery
                                ? purchaseDetail?.out_for_delivery_date
                                : null)}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 0.5 }}>
                            {label === "Step 3" &&
                            <>
                              {purchaseDetail?.in_transit
                                ? `Company: ${purchaseDetail?.in_transit_details?.company}, Phone No:  ${purchaseDetail?.in_transit_details?.contact_number} , Transport:  ${purchaseDetail?.in_transit_details?.transport}, Tracking ID:  ${purchaseDetail?.in_transit_details?.tracking_id}, Payment: ${purchaseDetail?.in_transit_details?.payment}, Date: ${purchaseDetail?.in_transit_details?.date}`:null}
                                {purchaseDetail?.in_transit ?
                                <a rel="noreferrer" href={purchaseDetail?.in_transit_details?.file} target="_blank" alt="proof" style={{marginLeft: '10px'}}>Proof</a> : null }
                                </>}
                            {label === "Step 4" &&
                              (purchaseDetail?.inspection
                                ? purchaseDetail?.inspection_report
                                : null)}
                            {label === "Step 5" &&
                              (purchaseDetail?.under_service_replace
                                ? Object.keys(purchaseDetail?.under_service_replace_details).map(key => `${key}: ${purchaseDetail?.under_service_replace_details[key]}`).join(', ')
                                : null)}
                            {label === "Step 6" &&
                            <>
                              {purchaseDetail?.returning
                                ? `Company: ${purchaseDetail?.return_transit_details?.company}, Phone No:  ${purchaseDetail?.return_transit_details?.contact_number} , Transport:  ${purchaseDetail?.return_transit_details?.transport}, Tracking ID:  ${purchaseDetail?.return_transit_details?.tracking_id}, Payment: ${purchaseDetail?.return_transit_details?.payment}, Date: ${purchaseDetail?.return_transit_details?.date}`:null}
                                {purchaseDetail?.returning ?
                                <a rel="noreferrer" href={purchaseDetail?.return_transit_details?.file} target="_blank" alt="proof" style={{marginLeft: '10px'}}>Proof</a> : null }
                                </>}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 0.5 }}>
                            {label === "Step 4" &&
                              (purchaseDetail?.inspection
                                ? purchaseDetail?.inspection_remarks
                                : null)}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Box sx={{textAlign:'end'}}>
                  {purchaseDetail?.warranty_created ?
                  !purchaseDetail?.claim_verification && (
                    <Button variant="contained" onClick={handleVerify}>Verify</Button>
                  ):null}
                  {purchaseDetail?.in_transit ?
                  !purchaseDetail?.inspection && (
                    <Grid item xs={12} sx={{ textAlign: "end" }}>
                      <Button onClick={handleOpen} variant="contained">
                        Update
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Inspection Reports
                          </Typography>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleInspection}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{ alignItems: "center" }}
                            >
                              <Grid item xs={12}>
                              <Typography sx={{mb: 1}}>Service/Replacement *</Typography>
                                <FormControl size="small" fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={setInsdata.inspection_report}
                                    onChange={(e) =>
                                      setInsdata({
                                        ...insdata,
                                        inspection_report: e.target.value,
                                      })
                                    }
                                  >
                                    <MenuItem value="Service">Service</MenuItem>
                                    <MenuItem value="Replacement">Replacement</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                              <Typography sx={{mb: 1}}>Remarks *</Typography>
                                <TextField
                                  size="small"
                                  rows={3}
                                  multiline
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={insdata.inspection_remarks}
                                  onChange={(e) =>
                                    setInsdata({
                                      ...insdata,
                                      inspection_remarks: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                              <Button variant="contained" type="submit">
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </Grid>
                  ):null}
                  {purchaseDetail?.inspection ?
                  !purchaseDetail?.under_service_replace && (
                    <Grid item xs={12} sx={{ textAlign: "end" }}>
                      <Button onClick={handleOpen} variant="contained">
                        Update
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Under Service/Replacement
                          </Typography>
                          <Grid container spacing={2} sx={{mt: 1}}>
                          <Grid item xs={4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Customer Name</Typography>
                <Typography >{purchaseDetail?.customer_name}</Typography>
                </Grid>
                <Grid item xs={4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Vehicle</Typography>
                <Typography >{purchaseDetail?.select_vehicle}</Typography>
                </Grid>
                <Grid item xs={4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Chasis No.</Typography>
                <Typography >{purchaseDetail?.chasis_number}</Typography>
                </Grid>
                          </Grid>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleService}
                          >
                          {(purchaseDetail?.inspection_report === "Service" && servicedata.service) ?
                            <Grid
                              container
                              spacing={2}
                              sx={{ alignItems: "center" }}
                            >
                              <Grid item xs={12}>
                              <FormGroup>
  <FormControlLabel control={<Checkbox defaultChecked 
    checked={servicedata.service}
      onChange={(e)=>{
        setServicedata({
          ...servicedata,
          service: e.target.checked 
        })
        setReplacedata({
          ...replacedata,
          service: e.target.checked 
        })
      }}
  />} label="Unable To Service A product So, Make Replacement" />
  </FormGroup>
                              </Grid>
                              <Grid item xs={12}>
                              <Typography sx={{mb: 1}}>Service On *</Typography>
                              <TextField
                              type="date"
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={servicedata.service_on}
                                  onChange={(e) =>
                                    setServicedata({
                                      ...servicedata,
                                      service_on: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                              <Typography sx={{mb: 1}}>Remarks *</Typography>
                                <TextField
                                  size="small"
                                  rows={3}
                                  multiline
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={servicedata.remarks}
                                  onChange={(e) =>
                                    setServicedata({
                                      ...servicedata,
                                      remarks: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                            :
                            <Grid
                              container
                              spacing={2}
                              sx={{ alignItems: "center" }}
                            >
                                                                                      {purchaseDetail?.inspection_report === "Service" &&

                                                          <Grid item xs={12}>
                              <FormGroup>
  <FormControlLabel control={<Checkbox defaultChecked 
    checked={replacedata.service}
      onChange={(e)=>{
        setServicedata({
          ...servicedata,
          service: e.target.checked 
        })
        setReplacedata({
          ...replacedata,
          service: e.target.checked 
        })
      }}
  />} label="Unable To Service A product So, Make Replacement" />
  </FormGroup>
                              </Grid>
                                                                                      }
                              <Grid item xs={6}>
                              <Typography sx={{mb: 1}}>Item *</Typography>
                                <FormControl size="small" fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={replacedata.item}
                                    onChange={(e) =>
                                      setReplacedata({
                                        ...replacedata,
                                        item: e.target.value,
                                      })
                                    }
                                  >
                                    <MenuItem value="Motor">Motor</MenuItem>
                                    <MenuItem value="Lead Acid Battery">Lead Acid Battery</MenuItem>
                                    <MenuItem value="Lithium-ion Battery">Lithium-ion Battery</MenuItem>
                                    <MenuItem value="Charger">Charger</MenuItem>
                                    <MenuItem value="Controller">Controller</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                              <Typography sx={{mb: 1}}>Product No *</Typography>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={replacedata.product_no}
                                    onChange={(e) =>
                                      setReplacedata({
                                        ...replacedata,
                                        product_no: e.target.value,
                                      })
                                    }
                                />
                              </Grid>
                              <Grid item xs={12}>
                              <Typography sx={{mb: 1}}>Remarks *</Typography>
                                <TextField
                                  size="small"
                                  rows={3}
                                  multiline
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={replacedata.remarks}
                                    onChange={(e) =>
                                      setReplacedata({
                                        ...replacedata,
                                        remarks: e.target.value,
                                      })
                                    }
                                />
                              </Grid>
                            </Grid>
                          }
                            <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                              <Button variant="contained" type="submit">
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </Grid>
                  ):null}
                    {purchaseDetail?.under_service_replace ?
                  !purchaseDetail?.returning && (
                    <Grid item xs={12} sx={{ textAlign: "end" }}>
                      <Button onClick={handleOpen} variant="contained">
                        Update
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            In Transit Details
                          </Typography>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleReturn}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{ alignItems: "center" }}
                            >
                              <Grid item xs={4}>
                                <Typography>Company *</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={transadat.company}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      company: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Contact Number *</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={transadat.contact_number}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      contact_number: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Transport *</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl size="small" fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={setTransadat.transport}
                                    onChange={(e) =>
                                      setTransadat({
                                        ...transadat,
                                        transport: e.target.value,
                                      })
                                    }
                                  >
                                    <MenuItem value="Road Way">Road Way</MenuItem>
                                    <MenuItem value="Sea Way">Sea Way</MenuItem>
                                    <MenuItem value="Fly Way">Fly Way</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Tracking ID *</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={transadat.tracking_id}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      tracking_id: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Payment *</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <FormControl size="small" fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={setTransadat.payment}
                                    onChange={(e) =>
                                      setTransadat({
                                        ...transadat,
                                        payment: e.target.value,
                                      })
                                    }
                                  >
                                    <MenuItem value="Paid">Paid</MenuItem>
                                    <MenuItem value="Un Paid">Un Paid</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Date *</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  type="date"
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  placeholder="GST988767567"
                                  variant="outlined"
                                  value={transadat.date}
                                  onChange={(e) =>
                                    setTransadat({
                                      ...transadat,
                                      date: e.target.value,
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Attach File's to Estimate</Typography>
                              </Grid>
                              <Grid item xs={8}>
                              <CloudUpload setLoader={setLoader} file={file} setFile={setFile}/>
                              </Grid>
                            </Grid>
                            <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                              <Button variant="contained" type="submit">
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </Grid>
                  ):null}
                  </Box>
                </Grid>
                <Grid item xs={0.2}>

                </Grid>
                <Grid item xs={7.8} sx={{border:'1px solid #bababa', p: 2}}>
                  <Typography variant="h5">Warranty</Typography>
                  <Divider sx={{my: 2}}/>
                  <Grid container spacing={2}>
                  <Grid item xs={12}>
                <Typography variant="h6">Customer Details</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Customer Name</Typography>
                <Typography >{purchaseDetail?.customer_name}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Vehicle</Typography>
                <Typography >{purchaseDetail?.select_vehicle}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Chasis No.</Typography>
                <Typography >{purchaseDetail?.chasis_number}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Date</Typography>
                <Typography >{`${(new Date(purchaseDetail?.created_at)).getDate()} / ${(new Date(purchaseDetail?.created_at)).getMonth()+1} / ${(new Date(purchaseDetail?.created_at)).getFullYear()}`}</Typography>
                </Grid>
                  </Grid>
                  <Divider sx={{my: 2}}/>
                  <Grid container spacing={2}>
                  <Grid item xs={12}>
                <Typography variant="h6">Claimed Details</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Selected Item</Typography>
                <Typography >{purchaseDetail?.item}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Item Brand</Typography>
                <Typography >{purchaseDetail?.item_brand}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Old Item No.</Typography>
                <Typography >{purchaseDetail?.item_no}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>New Item No</Typography>
                <Typography >{purchaseDetail?.new_item_no}</Typography>
                </Grid>
                  </Grid>
                  <Divider sx={{my: 2}}/>
                  <Grid container spacing={2}>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Date of Replacement</Typography>
                <Typography >{purchaseDetail?.date_of_replace}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Status</Typography>
                <Typography >{purchaseDetail?.status}</Typography>
                </Grid>
                {purchaseDetail?.item === "Battery" && 
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Bulge</Typography>
                <Typography >{purchaseDetail?.bulge}</Typography>
                </Grid>
                }
                  </Grid>
                  <Divider sx={{my: 2}}/>
                  <Grid container spacing={2}>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Capacity</Typography>
                <Typography >{purchaseDetail?.capacity}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Issue Type</Typography>
                <Typography >{purchaseDetail?.issue}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Still Warranty</Typography>
                <Typography >{purchaseDetail?.still_warranty}</Typography>
                </Grid>
                  </Grid>
                  <Divider sx={{my: 2}}/>
                  <Grid container spacing={2}>
                <Grid item xs={8} >
                <Typography variant="h6" sx={{fontSize: 15, fontWeight:'medium'}}>Remarks</Typography>
                <Typography >{purchaseDetail?.remarks}</Typography>
                </Grid>
                <Grid item xs={4} >
                <Typography variant="h6" sx={{fontSize: 15, fontWeight:'medium'}}>Upload Proof</Typography>
                <img src={purchaseDetail?.file_url} width={100} alt="proof"></img>
                </Grid>
                <Grid item xs={8} >
                <Typography variant="h6" sx={{fontSize: 15, fontWeight:'medium'}}>History of Warranty and Guarantee</Typography>
                <Typography >{purchaseDetail?.history_warranty}</Typography>
                </Grid>
                  </Grid>
                </Grid>
                </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Warrantydetail;
