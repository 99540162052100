import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Button, Grid, Paper, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import user_manual from '../../assets/images/user_manual.pdf';

const Support = ({show}) => {


  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px', display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Grid container maxWidth='sm' spacing={5} sx={{mt: 5}}>
        {/* <Grid item xs={12}>
            <Paper sx={{p: 5, boxShadow:'none', border:'1px solid', borderColor:'primary.main', background:'#eef8ff'}}>
                <a href="tel:+91 7656" style={{textDecoration:'none', color:'#000', display:'flex', justifyContent:'center', alignItems:'center', gap: 10}}>
                  <CallIcon/>
                  <Typography variant='h6'>+91 7656656555</Typography>
                </a>
            </Paper>
        </Grid> */}
        <Grid item xs={12}>
        <Paper sx={{p: 5, boxShadow:'none', border:'1px solid', borderColor:'primary.main', background:'#eef8ff'}}>
                <a href="mailto:info@dealerpro.in" style={{textDecoration:'none', color:'#000', display:'flex', justifyContent:'center', alignItems:'center', gap: 10}}>
                  <EmailIcon/>
                  <Typography variant='h6'>info@dealerpro.in</Typography>
                </a>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{p: 5, boxShadow:'none', border:'1px solid', borderColor:'primary.main', background:'#eef8ff'}}>
                <a href={user_manual} download="dealerpro_user_manual.pdf" style={{textDecoration:'none', color:'#000', display:'flex', justifyContent:'center', alignItems:'center', gap: 10}}>
                 <Button variant='contained' size='large'>Download User Manual</Button>
                </a>
            </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Support