import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, createpayment, getinvoicebycus, getinvoicebyid, getpaymentbycus, getpaymentbyid, updateinvoicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deleteproductstock, getproductstock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CustomTable2 from "../../components/table/paytable";
import { useReactToPrint } from 'react-to-print';

const columns2 = [
  { id: 'payment_id', label: 'Payment ID', minWidth: 200, align:'start' },
  { id: 'invoice_id', label: 'Invoice ID', minWidth: 150, align:'center' },
  { id: 'total', label: 'Amount', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];


const Viewinvoice = ({ setLoader, show }) => {

  const componentRef = useRef();

  const {state} = useLocation();

  const params = useParams()

  const {invoice: paymentData} = useSelector((state) => state.customer) || [];

  const {payments: paymentDat} = useSelector((state) => state.customer) || [];

  const filterPayment = paymentDat.filter(obj => obj.invoice_id === paymentData[0]?.invoice_id)

  const user = state.dealerData;

  console.log(user, state.viewData,)

  const dealerState =  JSON.parse(user.billing_address)?.state; 

  console.log(state.viewData, paymentData, user);


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(()=>{
    setLoader(true)
    const id = params.invoiceId;
      dispatch(getinvoicebyid(id))
      dispatch(getpaymentbycus(params.id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px", marginBottom:'150px'}}>
    <Grid container spacing={2}>
    <Grid
            item
            xs={6}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5">{params.invoiceId}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={handlePrint}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 1,
            }}
          >
            <LocalPrintshopOutlinedIcon sx={{ cursor: "pointer" }} />
            <Typography variant="h6">Print</Typography>
          </Grid>
    </Grid>
      <Box ref={componentRef} sx={{mt: 2, border:'1px solid #bababa', p: 3}}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
        <Grid item xs={6} sx={{display:'flex', alignItems:'center', justifyContent:'start', gap: 2}}>
                <Typography><img src={user?.logo_url} width={100}></img></Typography>
                <Box>
                <Typography variant="h6">{user.organization_name}</Typography>
                <Typography >{Object.values(JSON.parse(user?.billing_address)).join(', ')}</Typography>
                <Typography >{user.contact}</Typography>
                </Box>
            </Grid>
        <Grid item xs={6} sx={{textAlign:'end'}}>
                <Typography variant="h6">Invoice</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                <Typography >Billed to</Typography>
                <Typography variant="h6">{paymentData[0]?.customer_name}</Typography>
                <Typography >{Object.values(state?.viewData?.billing_address).join(', ')}</Typography>
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'end'}}>
                <Typography >Date: {paymentData[0]?.invoice_date}</Typography>
                <Typography >Invoice # : {paymentData[0]?.invoice_id}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                {paymentData[0]?.invoice_type==="Product" ? 
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={{background:'#595959', color:'#fff'}}>Items</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>Qty</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>Price</TableCell>
            <TableCell colSpan={2} align="center" sx={{background:'#595959', color:'#fff'}}>SGST</TableCell>
            <TableCell colSpan={2} align="center" sx={{background:'#595959', color:'#fff'}}>CGST</TableCell>
            <TableCell colSpan={2} align="center" sx={{background:'#595959', color:'#fff'}}>IGST</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{background:'#595959', color:'#fff'}}>%</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
            <TableCell sx={{background:'#595959', color:'#fff'}}>%</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
            <TableCell sx={{background:'#595959', color:'#fff'}}>%</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {paymentData[0]?.item_list && JSON.parse(paymentData[0]?.item_list).map((va, index) => {
          return(
            <TableRow key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {va?.vehicle?.product_name} <br></br>
Vehicle Model: {va?.variant?.variant_name} <br/>

Vehicle Color: {JSON.parse(va?.variant?.color_details).filter(obj => obj.code === va?.stock?.color)[0].name}
 <br/>

Chassis No: {va?.stock?.chasis_no} <br/>

Motor name: {va?.variant?.motor_name} <br/>

Motor No: {va?.stock?.motor_no} <br/>

Motor type: {va?.variant?.motor_type} <br/>

Motor warranty: {va?.variant?.motor_warranty_months}  <br/>

Battery name: {va?.variant?.battery_name} <br/>

Battery number: {va?.stock?.battery_no} <br/>

Battery type: {va?.variant?.battery_type} <br/>

Battery warranty: {va?.variant?.battery_warranty_months}  <br/>

Charger name: {va?.variant?.charger_name} <br/>

Charger type: {va?.variant?.charger_type} <br/>

Charger capacity: {va?.variant?.charger_capacity} <br/>

Charger warranty: {va?.variant?.charger_warranty_months}  <br/>

Controller No: {va?.stock?.controller_no} <br/>

Converter No: {va?.stock?.convertor_no} <br/>
              </TableCell>
              <TableCell >{va.vehicle?.hsn_no}</TableCell>
              <TableCell >{JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom}</TableCell>
              <TableCell >{paymentData[0]?.sgst !== 0 ? (va.vehicle?.sgst) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.sgst !== 0 ? (JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (va.vehicle?.sgst/100)) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.cgst !== 0 ? (va.vehicle?.cgst) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.cgst !== 0 ? (JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (va.vehicle?.cgst/100)) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.igst !== 0 ? (va.vehicle?.igst) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.igst !== 0 ? (JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (va.vehicle?.igst/100)) : '-'}</TableCell>
              <TableCell >{(paymentData[0]?.sgst !== 0 || paymentData[0]?.cgst !== 0 || paymentData[0]?.igst !== 0) ? (Number(JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom)+Number(JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (va.vehicle?.sgst/100))+Number(JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (va.vehicle?.cgst/100))+Number(JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (va.vehicle?.igst/100))) : Number(JSON.parse(va.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom)}</TableCell>
            </TableRow>
          )
        })}
        </TableBody>
      </Table>
      :
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={{background:'#595959', color:'#fff'}}>Item Details</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>HSN</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>Rate</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>Discount</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>Discount Amount</TableCell>
            <TableCell colSpan={2} align="center" sx={{background:'#595959', color:'#fff'}}>SGST</TableCell>
            <TableCell colSpan={2} align="center" sx={{background:'#595959', color:'#fff'}}>CGST</TableCell>
            <TableCell colSpan={2} align="center" sx={{background:'#595959', color:'#fff'}}>IGST</TableCell>
            <TableCell rowSpan={2} align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{background:'#595959', color:'#fff'}}>%</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
            <TableCell sx={{background:'#595959', color:'#fff'}}>%</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
            <TableCell sx={{background:'#595959', color:'#fff'}}>%</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {paymentData[0]?.item_list && JSON.parse(paymentData[0]?.item_list).map((va, index) => {
          return(
            <TableRow key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {va?.service_name}
              </TableCell>
              <TableCell >{va?.hsn_no}</TableCell>
              <TableCell >{va?.price}</TableCell>
              <TableCell >{va?.discount}</TableCell>
              <TableCell >{va.price * (Number(va?.discount) /100)}</TableCell>
              <TableCell >{paymentData[0]?.sgst !== 0 ? (va?.sgst || 0) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.sgst !== 0 ? va.price * (va?.sgst || 0 /100) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.cgst ? (va?.cgst || 0) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.cgst ? va.price * (va?.cgst || 0/100) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.igst ? (va?.igst || 0) : '-'}</TableCell>
              <TableCell >{paymentData[0]?.igst ? va.price * (va?.igst || 0/100) : '-'}</TableCell>
              <TableCell >{(paymentData[0]?.sgst !== 0 || paymentData[0]?.cgst !== 0 || paymentData[0]?.igst !== 0) ? (Number(va?.price)+Number(va?.price * (va?.sgst || 0/100))+Number(va.price * (va?.cgst || 0/100))+Number(va.price * (va?.igst || 0/100))-Number(va.price * (Number(va?.discount) /100))) : Number(va?.price)}</TableCell>
              
            </TableRow>
          )
        })}
        </TableBody>
      </Table>
                }
                </Grid>
                <Grid item xs={12}>
                <Divider sx={{mb: 2}}/>           
                </Grid>
            <Grid item xs={6}>
            <Typography >Terms & Condition</Typography>
                <Typography >{paymentData[0]?.terms_conditions}</Typography>
            </Grid>
            <Grid item xs={6} sx={{p: 2, background:'#eaeaea', textAlign:'end'}}>
            <Grid container spacing={2}>
          <Grid item xs={9} sx={{textAlign:'end'}}>
              <Typography variant="body1" sx={{mb: 1}}>Sub Total</Typography>
              <Typography  variant="body1"  sx={{mb: 1}}>Adjustment</Typography>
              <Typography  variant="body1"  sx={{mb: 1}}>GST</Typography>
              <Typography  variant="h6">Total Amount</Typography>
          </Grid>
          <Grid item xs={3} sx={{textAlign:'end'}}>
              <Typography variant="body1" sx={{mb: 1}}>₹ {paymentData[0]?.sub_total}</Typography>
              <Typography variant="body1" sx={{mb: 1}}>₹ {paymentData[0]?.discount}</Typography>
              <Typography variant="body1" sx={{mb: 1}}>₹ {Number(paymentData[0]?.sgst)+Number(paymentData[0]?.cgst)+Number(paymentData[0]?.igst)}</Typography>
              <Typography variant="h6">₹ {paymentData[0]?.total}</Typography>
          </Grid>
    </Grid>
            </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>           
        <Grid container sx={{p: 2, pb: 8, background:'#eaeaea'}}>
                  <Grid item xs={6}>
                  <Typography>Signature customer</Typography>
            </Grid>
            <Grid item xs={5} sx={{textAlign:'end'}}>
            <Typography>Signature authority</Typography>
                </Grid>
            </Grid>
      </Box>
      {filterPayment.length>0 &&
        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid item xs={12}>
          <CustomTable2 dealerData={user} viewData={state.viewData} columns={columns2} rows={filterPayment}/>
          </Grid>
      </Grid>
      }
    </div>
  );
};

export default Viewinvoice;