import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import CustomTable1 from '../../components/table/Wartab';
import { getwarranty } from '../../slices/warranty';

const columns = [
  { id: 'warranty_id', label: 'Warranty ID', minWidth: 200, align:'start' },
  { id: 'item', label: 'Item', minWidth: 150, align:'center' },
  { id: 'item_brand', label: 'Item Brand', minWidth: 150, align:'center' },
  { id: 'item_no', label: 'Old Item No', minWidth: 150, align:'center' },
  { id: 'new_item_no', label: 'New Item No', minWidth: 150, align:'center' },
  { id: 'date_of_replace', label: 'Date of Replace', minWidth: 150, align:'center' },
  { id: 'still_warranty', label: 'Warranty', minWidth: 150, align:'center' },
  { id: 'product_type', label: 'Product type', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const Warranty = ({setLoader, show}) => {

  const [filteredData, setFilteredData] = useState([]);

  const {warranty: warrantyData} = useSelector((state) => state.warranty) || [];

  const rows1 =  filteredData?.map((va)=>{
    return{
      ...va,
      status:  !va.warranty_created ? 'Not Created' : !va.claim_verification ? "Verify Pending" : !va.in_transit ? "Waiting Transit" : !va.inspection ? "Inspection Pending" : !va.under_service_replace ? "Waiting S/R " : !va.returning ? "Waiting Dispatched" : !va.out_for_delivery ? "Not Delivered" : "Delivered"      }
  });

  console.log(filteredData)

  const dispatch = useDispatch();

  const handleSearch = (query) => {
    const filtered = warrantyData.filter(item => (item.warranty_id).toLowerCase().includes(query));
    setFilteredData(filtered);
};

  useEffect(()=>{
    setLoader(true)
      dispatch(getwarranty())
      .then((response) => {
        console.log(response);
        setFilteredData(response?.payload?.warrantydata?.data);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={8} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            Received Warranty
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton handleSearch={handleSearch} setFilteredData={setFilteredData} placeholder="Search by Warranty ID"/>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable1 columns={columns} rows={rows1 || []} />
      </Box>
    </div>
  );
}

export default Warranty;