import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import { getcustomer, getinvoicebycus, getinvoicebyoem, getservicebydealer, getwarrantybydealer } from '../../slices/customer';
import CustomTable1 from '../../components/table/Invopentable';

const columns1 = [
  { id: 'invoice_id', label: 'Invoice ID', minWidth: 200, align:'start' },
  { id: 'total', label: 'Price', minWidth: 100, align:'start' },
  { id: 'pending_payments', label: 'Pending Amount', minWidth: 150, align:'center' },
  { id: 'invoice_date', label: 'Invoice Date', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];
  

const Invoice = ({setLoader, show}) => {

  const [filteredData, setFilteredData] = useState([]);

  const {oeminvoices: invoiceData} = useSelector((state) => state.customer) || [];

  const dispatch = useDispatch();

  const handleSearch = (query) => {
    const filtered = invoiceData.filter(item => (item.invoice_id).toLowerCase().includes(query));
    setFilteredData(filtered);
};

  useEffect(()=>{
    setLoader(true)
    dispatch(getinvoicebyoem())
      .then((response) => {
        setFilteredData(response.payload.customerdata.data);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={8} sx={{paddingTop: 0}}>
            <Typography variant='h5'>
            Invoices
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton handleSearch={handleSearch} setFilteredData={setFilteredData} placeholder="Search by Invoice ID"/>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
      <CustomTable1 columns={columns1} rows={filteredData}/>
        </Box>
    </div>
  );
}

export default Invoice;